import React from 'react';
import styled from 'styled-components';

import { mobileThresholdPixels } from './styledComponents';
import bannerImg from '../../../assets/jobOffer/jobOfferBanner.jpg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 35vh;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 20vh;
  }
`;

const Img = styled.img`
  object-fit: cover;
  width: 100vw;
`;

const JobOfferBanner = () => (
  <Container>
    <Img src={bannerImg} alt="L'équipe Tilli autour d'un table" />
  </Container>
);

export default JobOfferBanner;
